@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

.bubble {
  position: relative;
  animation: bounceLeft 2s infinite;
}

.bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: transparent #ffffff;
  border-width: 14px 14px 14px 0;
  top: 50%;
  left: -14px;
  margin-top: -14px;
}

html {
  touch-action: pan-y; /* prevent pinch zoom */
}

.phoneoutline {
  position: absolute;
  top: 20px;
  z-index: 100;
  border-top: 40px solid #000000;
  border-bottom: 40px solid #000000;
  border-left: 10px solid #000000;
  border-right: 10px solid #000000;
  padding: 0;
  border-radius: 40px;
}

@keyframes bounceLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(20px);
  }
  0% {
    transform: translateX(0);
  }
}